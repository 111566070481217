$feature-redesign: false;
@import '../common/variables';
@import '../common/mixins';

.amp-dc-video-container {
    margin: 0;
    padding: 0;
    position: relative;
    white-space: nowrap;
    width: 100%;
    display: block;

    & > a {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &[data-target="false"] {
        display: flex;
        flex-direction: column;
    }

    &[data-target="true"] {
        display: -webkit-box;
    }

    .amp-dc-video-wrap, 
    .amp-dc-video {
        width: 100%;
        height: 100%;
    }

    @include aspect-ratio-breakpoint(1, 1, "desktop", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(4, 3, "desktop", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(3, 4, "desktop", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(9, 16, "desktop", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(16, 9, "desktop", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(8, 3, "desktop", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(16, 4, "desktop", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(2, 1, "desktop", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(3, 2, "desktop", ".amp-dc-video-wrap");

    @include aspect-ratio-breakpoint(1, 1, "tablet", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(4, 3, "tablet", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(3, 4, "tablet", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(4, 3, "tablet", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(9, 16, "tablet", ".amp-dc-video-wrap");

    @include aspect-ratio-breakpoint(1, 1, "mobile", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(4, 3, "mobile", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(3, 4, "mobile", ".amp-dc-video-wrap");
    @include aspect-ratio-breakpoint(9, 16, "mobile", ".amp-dc-video-wrap");

    .amp-dc-video-pic {
        display: block;
    }

    video {
        width: 100%;
        user-select: none;
    }

    a {
        text-decoration: none;
    }

    height: 100%;
    position: relative;
    white-space: nowrap;
    letter-spacing: 0.8px;
    font-weight: 400;

    @if ($feature-redesign) {
        font-weight: normal;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .amp-dc-hide {
        display: none;
    }

    .amp-dc-video-info {
        font-size: 22px;
        display: flex;
        flex-direction: column;
        background: none;
        color: #000;
        padding: 15px;
        margin: auto auto;

        .align-left {
            align-self: flex-start;
            text-align: left;

        }

        .align-center {
            align-self: center;
            text-align: center;

        }

        .align-right {
            align-self: flex-end;
            text-align: right;
        }

        &.vertical-align-top {
            top: 5%;
        }

        &.vertical-align-bottom {
            bottom: 5%;
        }
    }

    .amp-dc-video-info-wrap {
        color: #fff;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        white-space: normal;
        display: flex;
        justify-content: center;
        align-items: center;

        &[data-align='left'] {
            .amp-dc-video-info {
                position: absolute;
                left: 15%;
            }
        }

        &[data-align='right'] {
            .amp-dc-video-info {
                position: absolute;
                right: 15%;
            }
        }

        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -1em;
        }
    }

    .amp-dc-video-header {
        font-size: 32px;
    }

    .amp-dc-video-preheader,
    .amp-dc-video-subheader {
        font-size: 16px;
    }

    .amp-dc-video-preheader,
    .amp-dc-video-subheader,
    .amp-dc-video-header {
        &[data-align='left'] {
            left: 0;
        }

        &[data-align='right'] {
            right: 0;
        }
    }

    .amp-dc-video-button {
        font-size: 20px;
        margin-top: 15px;
        display: inline-block;
        min-width: 40%;
        padding: 17px 15px;
        background: black;
        color: white;
        box-sizing: border-box;

        &.black {
            background: #000;
            color: #fff;
        }

        &.white {
            background: #fff;
            color: #000;
        }

        &[data-align='left'] {
            left: 0;
        }

        &[data-align='right'] {
            right: 0;
        }

        &.btn {
            border: 1px solid $black;
            display: inline-block;
            font-family: $font-family-theinhardt;
            font-size: $font-size-secondary;
            font-weight: 500;

            @if ($feature-redesign) {
                font-family: $font-family-sans;
                font-weight: normal;
            }
            letter-spacing: 0.08em;
            line-height: 16px;
            outline: 0;
            padding: 13px 15px;
            text-align: center;
            text-transform: uppercase;
            transition: $transition-ease-short;
            transition-property: background-color, border-color, color;
            user-select: none;
        
            @media (--md-down) {
                font-size: 13px;
            }
        
            &:hover {
                text-decoration: none;
            }
        
            &__text-active {
                display: none;
            }
        
            &.m-active & {
                &__text {
                    display: none;
                }
        
                &__text-active {
                    display: inline;
                }
            }
            &.btn-primary {
                &:hover {
                    background-color: $white;
                    color: $black;
                }
            }

            &.btn-outline-primary {
                background-color: transparent;
                color: $black;
            
                &:not(:disabled):not(.m-disabled):not(.m-animate):hover {
                    background-color: $black;
                    color: $white;
                }
            
                .non-touch &.m-animate:not(:disabled):not(.m-disabled):hover {
                    animation: bg-hover-black 1.5s ease-in-out;
                }
            
                &.m-disabled,
                &:disabled {
                    border-color: $gray-200;
                    color: $gray-300;
                }
            }

            &.btn-underlined {
                cursor: pointer;
                background: transparent;
                position: relative;
                border: 0;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 2px;
                    width: 100%;
                    background: black;
                    transition: width 0.4s ease-out;
                }
            }

            &.btn-underlined:hover {
                &::after {
                    width: 0px;
                }
            }
        }
    }

    .amp-dc-video-info-wrap {
        width: 100%;

        &.amp-dc-info-bottom {
            position: relative;
            top: 0 !important;
            left: 0 !important;
            right: auto;
            display: flex;
            width: 100%;
            text-align: center;
            padding: 16px 15px 10px 15px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            height: auto;

            .amp-dc-video-info {
                width: inherit;
                margin: unset;
            }

            &[data-align='left'] {
                justify-content: flex-start;

                .amp-dc-video-info {
                    position: unset;
                }
            }

            &[data-align='right'] {
                justify-content: flex-end;

                .amp-dc-video-info {
                    position: unset;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .amp-dc-video-info-wrap {
            width: 100%;

            &[data-align='left'] {
                .amp-dc-video-info {
                    position: absolute;
                    left: 10%;
                }
            }

            &[data-align='right'] {
                .amp-dc-video-info {
                    position: absolute;
                    right: 10%;
                }
            }

            &.amp-dc-info-bottom-mobile {
                position: relative;
                top: 0 !important;
                left: 0 !important;
                right: auto;
                display: flex;
                width: 100%;
                text-align: center;
                padding: 16px 15px 10px 15px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                height: auto;

                .amp-dc-video-info {
                    width: inherit;
                    margin: unset;
                }

                &[data-align='left'] {
                    justify-content: flex-start;
                    text-align: left;

                    .amp-dc-video-info {
                        position: unset;
                    }
                }

                &[data-align='right'] {
                    justify-content: flex-end;
                    text-align: right;

                    .amp-dc-video-info {
                        position: unset;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .amp-dc-video-container {
        display: flex;
        flex-direction: column;
    }
}