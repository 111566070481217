$feature-redesign: false;
@import '../next-variables.scss';
@import '../next-typography.scss';

.next-storytelling {
    --block-width: 100vw;
    --image-offset-right: 42px;
    --padding-horizontal-total: 50px;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px var(--cta-horizontal-margin) 0;
    background-color: $color-next-story;

    &__image {
        width: calc((var(--block-width)*6/7) - var(--image-offset-right) - var(--padding-horizontal-total));
        height: calc(var(--block-width) - var(--image-offset-right) - var(--padding-horizontal-total));
    }

    &__text {
        padding-bottom: 6.4rem;
        color: $textBlack;
        padding-top: 2.4rem;
        @include n3;

        @media screen and (min-width: $sm) {
            padding-bottom: 12.8rem;
            display: none;
        }

        @media screen and (min-width: 1470px) {
            display: block;
        }                    
    }

    @media screen and (min-width: $sm) {
        --block-width: 50vw;
    }

    @media screen and (min-width: $lg) {
        padding: 0 var(--cta-horizontal-margin) 15rem var(--cta-horizontal-margin);

        &__image {
            padding-top: 4rem;
        }
    }
}