$feature-redesign: false;
$grid_gap: 20px;

@import '../common/mixins';

.three-grid-container-30-30-30 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $grid_gap;
    grid-gap: $grid_gap;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .grid-tile {
        &__wrapper {
            display: flex;
            height: 100%;

            .amp-dc-hero-banner {
                width: 100%;
            }
        }
    }

    &[data-cta-bottom="true"] {
        .amp-dc-image[data-target="false"], .amp-dc-video-container[data-target="false"] {
            display: flex;
            flex-direction: column;
        }

        .amp-dc-image[data-target="true"], .amp-dc-video-container[data-target="true"] {
            display: -webkit-box;
        }
    }

    &[data-mobile-layout="true"],
    &[data-cta-bottom="true"] {
        @media screen and (max-width: 768px) {
            .grid-tile:not(.grid-tile__text) {
                .grid-tile__wrapper {
                    height: auto;
                }
            }
        }
    }

    picture {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: baseline;

        img {
            display: inline-block;
            object-fit: cover;
            object-position: 50% top;
            height: 100%;
        }
    }

    @include text-ratio(1, 1, "desktop");
    @include text-ratio(4, 3, "desktop");
    @include text-ratio(9, 16, "desktop");
    @include text-ratio(16, 4, "desktop");
    @include text-ratio(16, 9, "desktop");

    @include text-ratio(1, 1, "tablet");
    @include text-ratio(4, 3, "tablet");
    @include text-ratio(9, 16, "tablet");

    @include text-ratio(3, 4, "mobile");
    @include text-ratio(1, 1, "mobile");

    .amp-dc-image-picture-wrap,
    .amp-dc-image-picture-wrap img {
        height: 100%;
    }

    .amp-dc-component_text {
        overflow: hidden;
    }

    .amp-dc-video,
    .amp-dc-image,
    .amp-dc-card-wrap,
    .amp-dc-card-img-wrap,
    .amp-dc-video-wrap,
    .amp-dc-component_text {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .three-grid-container-30-30-30 {
        grid-template-columns: minmax(0, 1fr);
        gap: #{$grid_gap - 5px};
        grid-gap: #{$grid_gap - 5px};
    }
}
