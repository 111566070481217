$feature-redesign: false;
$xxl: 1800px;
$lg: 1200px;
$md: 960px;
$sm: 769px;
$xs: 540px;

:root {
    --lg: #{$lg};
    --md: #{$md};
    --sm: #{$sm};

    --homepage-item-height: calc(7/6 * 100vw);

    @media screen and (min-width: $sm) {
        --homepage-item-height: calc(7/12 * 100vw);
    }
}

$white: #fff;
$black: #000;
$hush-white: #fcf9f6;
$wattle-red: #d23c2c;
$gazpacho: #c53827;
$textBlack: #141110;
$textBlackBg: #FCF9F6;
$color-next-story: #B2D6E4;

$earthenBrown: #57321D;
$homePageBg: #FCF9F6;
$cta-clp-padding: 40px;
$clp-height: 3rem;
$cta-width: calc($clp-height*7);

$font-family-sans: "ABCROM Book", Arial, sans-serif;
$font-family-serif: "PortraitText", "Times New Roman", serif;
