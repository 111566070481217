$feature-redesign: false;
@import '../next-homepage.scss';

.next-template-d {
    @include next-homepage;

    &__cover {
        margin-top: 60px;

        @media screen and (min-width: 375px) {
            margin-top: 74px;
        }

        @media screen and (min-width: 404px) {
            margin-top: 77px;
        }

        @media screen and (min-width: 1280px) {
            margin-top: 85px;
        }

        @media screen and (min-width: 1346px) {
            margin-top: 101px;
        }
    }

    &__transparent {
        margin-top: 0;
    }
}
