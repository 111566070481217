$feature-redesign: false;
$grid_gap: 20px;

@import '../common/mixins';

.four-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 100%;
    gap: $grid_gap;
    grid-gap: $grid_gap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &[data-ratio="1:1"] {
        @include aspect-ratio-styles(1, 1, ".main-card .amp-dc-image");
        @include aspect-ratio-styles(2, 1, ".right-container__wide:not(.wide-card__text) .amp-dc-card-list__area");
        @include aspect-ratio-styles(1, 1, ".amp-dc-card-list-wrap .amp-dc-card-list__area");
    }

    &[data-ratio="3:4"] {
        @include aspect-ratio-styles(3, 4, ".main-card .amp-dc-image");
        @include aspect-ratio-styles(3, 4, ".amp-dc-card-list-wrap .amp-dc-card-list__area");
        @include aspect-ratio-styles(3, 2, ".right-container__wide:not(.wide-card__text) .amp-dc-card-list__area");
    }

    &[data-ratio="4:3"] {
        @include aspect-ratio-styles(4, 3, ".main-card .amp-dc-image");
        @include aspect-ratio-styles(4, 3, ".amp-dc-card-list-wrap .amp-dc-card-list__area");
        @include aspect-ratio-styles(8, 3, ".right-container__wide:not(.wide-card__text) .amp-dc-card-list__area");
    }

    @media screen and (min-width: 768px) {
        &[data-ratio="1:1"] {
            @include aspect-ratio-styles(1, 1, ".main-card__text");
            @include aspect-ratio-styles(2, 1, ".wide-card__text .amp-dc-card-list__area");
        }

        &[data-ratio="3:4"] {
            @include aspect-ratio-styles(3, 4, ".main-card__text");
            @include aspect-ratio-styles(3, 2, ".wide-card__text .amp-dc-card-list__area");
        }

        &[data-ratio="4:3"] {
            @include aspect-ratio-styles(4, 3, ".main-card__text");
            @include aspect-ratio-styles(8, 3, ".wide-card__text .amp-dc-card-list__area");
        }
    }

    .amp-dc-component_text {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .right-container a {
        height: 100%;
        text-decoration: none;
    }

    .amp-dc-card-list__area,
    .amp-dc-card,
    .amp-dc-image,
    .amp-dc-image-picture-wrap,
    .right-container {
        height: 100%;
    }

    .main-card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        &__wrapper {
            display: flex;
            width: 100%;
            height: 100%;

            .amp-dc-hero-banner {
                width: 100%;
            }
        }
    }

    .left-container,
    .right-container {
        width: 100%;
        height: 100%;

        .amp-dc-image {
            a {
                height: 100%;
                text-decoration: none;
            }
        }

        picture {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: baseline;

            img {
                display: inline-block;
                object-fit: cover;
                object-position: 50% top;
                height: 100%;
            }
        }
    }

    .right-container {
        display: grid;
        grid-template-rows: repeat(2, minmax(0, 1fr));
        grid-template-columns: 100%;
        gap: $grid_gap;
        grid-gap: $grid_gap;

        &__wide > * {
            grid-column: 1 / span 2;
            grid-row: 1 / span 1;
        }
    }

    .amp-dc-card-list-wrap {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        height: 100%;
        gap: $grid_gap;
        grid-gap: $grid_gap;

        .amp-dc-card-list__area {
            flex: 1;
        }

        .amp-dc-card-text-wrap {
            padding: 0;
        }
    }

    .amp-dc-video,
    .amp-dc-card-wrap,
    .amp-dc-card-img-wrap,
    .amp-dc-video-wrap {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .four-grid-container {
        grid-template-columns: 100%;
        grid-template-rows: auto;
        gap: #{$grid_gap - 5px};
        grid-gap: #{$grid_gap - 5px};

        .right-container {
            grid-template-rows: auto;
        }

        .right-container,
        .amp-dc-card-list-wrap {
            gap: #{$grid_gap - 5px};
            grid-gap: #{$grid_gap - 5px};
        }
    }
}