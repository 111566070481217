$feature-redesign: false;
@import "../../../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "../../../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.theme";
@import '../next-variables.scss';
@import '../next-typography.scss';

$smItemHeight: 356px;
$arrowOffset: -6rem;
$contentHeight: 100%;
$textHeight: 5.4rem;
$carouselMaxWidth: 1376px;
$imageRatio: 4/3; // width / height
$desktopImageContainerWidth: 50vw;
$carouselHorizontalPaddingMobile: 24px;
$carouselHorizontalPaddingTablet: 20px;
$carouselHorizontalPaddingDesktop: 150px;
$gap: 20px;

.next-carousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: $homePageBg;
    padding: 0 $carouselHorizontalPaddingMobile;
    margin: 64px 0 50px 0;

    @media screen and (min-width: $sm) {
        margin: 80px 0;
        padding: 0 $carouselHorizontalPaddingTablet;
    }

    @media screen and (min-width: $lg) {
        padding: 0 $carouselHorizontalPaddingDesktop;
        margin: 160px 0;
    }

    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        max-width: $carouselMaxWidth;
        margin: auto;
    }

    &__items {
        display: flex;
        width: 100%;
        position: relative;
        align-self: center;
        height: 100%;
    }

    &__item {

        display: flex;
        flex-direction: column;
        @include n6;

        &__title {
            text-transform: uppercase;
            user-select: none;
        }

        &__description {
            user-select: none;
            white-space: normal;
        }

        &:hover .next-carousel__image-container {
            opacity: 0.5;
        }
    }

    &__image-container {

        img {
            user-select: none;
            aspect-ratio: $imageRatio;
        }
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.4rem;
        height: $textHeight;

        &:hover {
            color: $earthenBrown;
        }

        @media screen and (min-width: $sm) {
            justify-content: flex-end;
        }

        @media screen and (max-width: $sm) {
            justify-content: flex-start;
            padding-top: 1rem;
        }
    }

    &__title {
        @include n3;
        margin-bottom: 40px;
        text-transform: uppercase;

        @media screen and (min-width: $sm) {
            margin-bottom: 50px;
        }

        @media screen and (min-width: $lg) {
            margin-bottom: 100px;
        }
    }

    &__wrapper-link {
        text-decoration: none;
        color: inherit;
        display: inline-block;
        height: $contentHeight;
        display: grid;
        grid-template-rows: 1fr $textHeight;

        &:hover {
            text-decoration: none;
        }

        @media screen and (max-width: $sm) {
            height: 100%;
            margin-bottom: 25px;
        }
    }


    &__more-stories {
        display: flex;
        align-items: center;
        position: relative;
        padding-bottom: 0;

        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            
            @media screen and (min-width: $sm) {
                border-width: 0 1.5px 0 1.5px;
                border-style: solid;
                border-color: #141110;
                aspect-ratio: $imageRatio;
            }
        }

        &__link {
            color: $textBlack;
            margin-top: 15px;
            border: 1px solid black;
            width: 261px;
            text-align: center;
            height: 37.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            text-decoration: none;
            margin-bottom: 1px;
            @include n8;

            @media screen and (min-width: $sm) {
                height: fit-content;
                margin: none;
                border: none;
                width: unset;
                display: block;
                margin-top: 0;                
            }
        }

        &:hover {
            color: $earthenBrown;

            .next-carousel__more-stories__container {
                border-color: $earthenBrown;

                @media screen and (min-width: $sm) {
                    background: white;
                }
            }

            .next-carousel__more-stories__link {
                color: $earthenBrown;
                text-decoration: none;
            }
        }
    }



    .glide {
        display: flex;
        justify-content: center;
        align-items: center;

        &__track {
            height: 100%;
            width: 100%;
            min-width: 100%;
        }

        &__slides {
            height: 100%;
            flex-direction: column;
            width: 100%;
            max-width: 100%;

            @media screen and (min-width: $sm) {
                flex-direction: row;
                width: initial;
                max-width: unset;
            }
        }

        &__arrow {
            top: calc(($contentHeight - $textHeight) / 2);
            border: none;
            box-shadow: none;
            color: $textBlack;
            font-size: 2.4rem;

            @media (max-width: $lg) {
                display: none;
            }

            &[hidden] {
                display: none;
            }

            &:hover {
                color: $earthenBrown;
            }

            &--left {
                left: $arrowOffset;
                transform: rotate(180deg) translateY(44%);
            }

            &--right {
                right: $arrowOffset;
            }
        }
    }
}