$feature-redesign: false;
.amp-dc-promo-banner {
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;

    * {
        margin: 0;
        padding: 0;
    }

    .amp-dc-promo-banner-wrap {
        display: table;
        vertical-align: top;
        width: 100%;
    }

    .amp-dc-promo-block {
        display: table-cell;
        overflow: visible;
        border: 4px solid transparent;
    }

    .amp-dc-promo-wrap {
        &>a {
            display: block;
            overflow: hidden;
            border: 1px solid #eeeeee;
            text-decoration: none;
            padding: 8px;

            & :hover {
                * {
                    opacity: 0.8;
                }
            }
        }
    }

    .amp-dc-promo-text-wrap {
        overflow: hidden;
    }

    .amp-dc-promo-ico {
        float: left;
        margin-right: 15px;
        min-height: 40px;
        background-size: cover;
        background-repeat: no-repeat;

        &>img {
            display: block;
        }
    }

    .amp-dc-promo-head-text {
        font-weight: bold;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        font-size: 16px;
        color: #424242;
    }

    .amp-dc-promo-content-text {
        font-size: 14px;
        color: #9b9b9b;
        margin-top: 3px;
    }

    .amp-dc-image-img {
        width: 100%;
        font-size: 14px;
        display: block;
    }

    .amp-dc-promo-center {
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .amp-dc-promo-banner {
        white-space: normal;

        .amp-dc-promo-banner-wrap {
            position: relative;
            display: block;
        }

        .amp-dc-promo-block {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            overflow: hidden;
            -webkit-transition: opacity 1s ease-in-out;
            -moz-transition: opacity 1s ease-in-out;
            -ms-transition: opacity 1s ease-in-out;
            -o-transition: opacity 1s ease-in-out;
            opacity: 0;

            &.dc-fade-in {
                position: relative;
                -webkit-transition: opacity 1.5s ease-in-out;
                -moz-transition: opacity 1.5s ease-in-out;
                -ms-transition: opacity 1.5s ease-in-out;
                -o-transition: opacity 1.5s ease-in-out;
                animation-delay: 0.5s;
                -moz-animation-delay: 0.5s;
                -webkit-animation-delay: 0.5s;
                opacity: 1;
            }
        }

        .amp-dc-promo-head-text {
            font-size: 15px;
        }

        .amp-dc-promo-content-text {
            font-size: 13px;
        }

        .amp-dc-promo-ico {
            min-height: 35px;
        }
    }
}