@import './next-variables.scss';

html {
    @if ($feature-redesign) {
        @media (min-width: 544px) {
            font-size: 13.5px;
        }
        @media (min-width: 769px) {
            font-size: 12px;
        }
        @media (min-width: 992px) {
            font-size: 12.75px;
        }
        @media (min-width: 1200px) {
            font-size: 13.5px;
        }
        @media (min-width: 1800px) {
            font-size: 16px;
        }
    }
}


@mixin n1 {
    font-family: $font-family-serif;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.8rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 3.375rem;
        line-height: 4rem;
    }
}

@mixin n2 {
    font-family: $font-family-sans;
    font-size: 2.24rem;
    font-weight: normal;
    line-height: 2.8rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 3.25rem;
        line-height: 4rem;
    }
}

@mixin n3 {
    font-family: $font-family-serif;
    font-size: 1.76rem;
    font-weight: 500;
    line-height: 2.2rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.75rem;
        line-height: 2.1875rem;
    }
}

@mixin n4 {
    font-family: $font-family-sans;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 2.2rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.625rem;
        line-height: 2.1875rem;
    }
}

@mixin n5 {
    font-family: $font-family-serif;
    font-size: 1.28rem;
    font-weight: 500;
    line-height: 1.76rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.25rem;
        line-height: 1.5625rem;
    }
}

@mixin n6 {
    font-family: $font-family-sans;
    font-size: 1.16rem;
    font-weight: normal;
    line-height: 1.6rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.1875rem;
        line-height: 1.5625rem;
    }
}

@mixin n7 {
    font-family: $font-family-serif;
    font-size: 1.36rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.0625rem;
        line-height: 1.5rem;
    }
}

@mixin n8 {
    font-family: $font-family-sans;
    font-size: 1.04rem;
    font-weight: normal;
    line-height: 1.3rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.0625rem;
        line-height: 1.4375rem;
    }
}

@mixin n9 {
    font-family: $font-family-serif;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@mixin n10 {
    font-family: $font-family-sans;
    font-size: 0.96rem;
    font-weight: 500;
    line-height: 1.3rem;
    letter-spacing: 0px;


    @media (--md-up) {
        font-size: 0.9375rem;
        line-height: 1.25rem;
    }
}

@mixin n11 {
    font-family: $font-family-serif;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.8rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    @media (--md-up) {
        font-size: 0.875rem;
        line-height: 100%;
    }
}

