$feature-redesign: false;
.amp-dc-external-block {
    .test {
        padding: 20px;
        background: #efefef;
        border-radius: 5px;
        margin: 20px;
        min-width: 360px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .test>div {
        padding: 20px;
        background: #bff2b5;
        border-radius: 5px;
        margin: 20px;
    }
}