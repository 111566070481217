@import '../next-variables.scss';

@mixin next-homepage {
    &__product-carousel {
        margin-top: 14px;
        margin-bottom: 64px;
        min-height: 463px;

        @media screen and (min-width: $sm) {
            margin-top: 46px;
            margin-bottom: 80px;
            min-height: 457px;
        }

        @media screen and (min-width: $lg) {
            margin-top: 110px;
            margin-bottom: 160px;
            min-height: 582px;
        }

        .b-carousel {
            &__title {
                margin-bottom: 50px;

                @media screen and (min-width: $lg) {
                    margin-bottom: 100px;
                }
            }
        }
    }
}