$feature-redesign: false;
@import '../common/mixins';

.five-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    grid-gap: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;

    &[data-ratio="1:1"] {
        @include aspect-ratio-styles(1, 1, ".main-card .amp-dc-image");
        @include aspect-ratio-styles(1, 1, ".right-container");
    }

    &[data-ratio="3:4"] {
        @include aspect-ratio-styles(3, 4, ".main-card .amp-dc-image");
        @include aspect-ratio-styles(3, 4, ".right-container");
    }

    &[data-ratio="4:3"] {
        @include aspect-ratio-styles(4, 3, ".main-card .amp-dc-image");
        @include aspect-ratio-styles(4, 3, ".right-container");
    }

    @media screen and (min-width: 768px) {
        &[data-ratio="1:1"] {
            @include aspect-ratio-styles(1, 1, ".main-card__text");
        }

        &[data-ratio="3:4"] {
            @include aspect-ratio-styles(3, 4, ".main-card__text");
        }

        &[data-ratio="4:3"] {
            @include aspect-ratio-styles(4, 3, ".main-card__text");
        }
    }

    .main-card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .amp-dc-component_text {
            overflow: hidden;
            width: 100%;
            height: 100%;
        }

        &__wrapper {
            display: flex;
            width: 100%;
            height: 100%;

            .amp-dc-hero-banner {
                width: 100%;
            }
        }
    }

    .amp-dc-image-picture-wrap {
        height: 100%;
    }
    
    .left-container {
        width: 100%;
    }
    
    .amp-dc-card-list-wrap {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
        height: 100%;
        .amp-dc-card-text-wrap {
            padding: 0;
        }
    }

    .amp-dc-card {
        width: 100%;
        height: 100%;
    }

    .amp-dc-video,
    .amp-dc-image,
    .amp-dc-card-wrap,
    .amp-dc-card-img-wrap,
    .amp-dc-hero-banner,
    .amp-dc-video-wrap {
        height: 100%;
        width: 100%;
    }

    .amp-dc-component_text {
        width: 100%;
        height: 100%;
    }

    .amp-dc-image img,
    .amp-dc-video {
        height: 100%;
    }

    .amp-dc-image img {
        height: 100%;
        object-fit: cover;
    }

    .card-container {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .five-grid-container {
        grid-template-columns: 100%;
        gap: 15px;
        grid-gap: 15px;
    }
}