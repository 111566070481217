$feature-redesign: false;
@import '../next-typography.scss';

$sm: 768px;
$md: 960px;
$lg: 1200px;

.next-about-us {
    --centered-width: 66%;
    margin-top: 50px;

    @media screen and (min-width: $sm) {
        --centered-width: 59%;
        margin-top: 75px;
    }

    @media screen and (min-width: $lg) {
        --centered-width: 56%;
        margin-top: 130px;
    }

    @include n3;

    h1,
    h3 {
        @include n3;
    }

    h2 {
        @include n1;
    }

    &__content {
        max-width: calc(1374px + 160px); // max-width of images/gap + desktop horizontal padding
        margin: auto;
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: $sm) {
            padding: 0 80px;
        }

        h3.indent-1,
        h1.indent-1 {
            @media screen and (max-width: $sm) {
                width: 340px;
            }
            
            @media screen and (min-width: $sm) {
                width: 602px;
            }
            
            @media screen and (min-width: $lg) {
                width: 910px;
            }
        }
    }

    .next-twin {
        --homepage-item-height: calc(7/6 * 100vw);

        @media screen and (min-width: $sm) {
            --homepage-item-height: calc(7/12 * 100vw);
        }
    }

    .table {
        width: 100% !important;
        display: table;

        td {
            padding: 0;
            border: none;

            &:first-child {
                padding-right: 5px;
            }

            &:last-child {
                padding-left: 5px;
            }

            @media screen and (min-width: $sm) {
                &:first-child {
                    padding-right: 10px;
                }

                &:last-child {
                    padding-left: 10px;
                }
            }
        }
       
        &.indent-1 {
            table {
                display: flex;
                justify-content: center;
            }

            table tbody tr {
                display: flex;
                justify-content: center;
                width: 100%;
            }

            td:first-child {
                @media screen and (max-width: $sm) {
                    width: 340px;
                }
                
                @media screen and (min-width: $sm) {
                    width: 602px;
                }
                
                @media screen and (min-width: $lg) {
                    width: 910px;
                }
                
                padding-right: 0;

                @media screen and (min-width: $sm) {
                    padding-right: 10px;
                }
            }

            td:last-child {
                display: none;            
            }
        }
    }

    .indent-2 {
        text-align: left;
        margin: auto;

        @media screen and (min-width: $sm) {
            width: 80%;
        }

        @media screen and (min-width: $lg) {
            width: 73%;
        }
    }

    .indent-3 {
        padding-top: 20px;

        table {
            @include n5;
        }
        
        td:first-child {
            width: 100% !important;

            @media screen and (min-width: $sm) {
                width: 50% !important;
            }

            @media screen and (min-width: $lg) {
                width: 30% !important;
            }
        }
    }

    figure {
        margin: 0;
        display: inline-flex;
        width: initial !important;

        img {
            width: 50%;
            margin: auto;
        }

        &.image-centered {
            justify-content: center;
            margin: auto;
            width: var(--centered-width);
            margin-bottom: 25px;
        }

        &.image {        
            &-1 {                
                @media screen and (min-width: $sm) {
                    width: 80% !important;
                }
            }

            &-2 {                
                @media screen and (min-width: $sm) {
                    width: 64% !important;
                }
            }

            &-3 {            
                @media screen and (min-width: $sm) {
                    width: 48% !important;
                }
            }
        }
    }

    .image-caption {
        margin: auto;
        @include n5;

        @media screen and (min-width: $sm) {
            width: var(--centered-width);
        }

        span {
            display: block;

            @media screen and (min-width: $lg) {
                width: 50%;
            }
        }
    }

    .para-title-indent {
        display: block;
        margin: auto;
        text-align: left;

        @media screen and (min-width: $sm) {
            width: 80%;
        }

        @media screen and (min-width: $lg) {
            width: 74%;
        }
    }

    table figure.image img {        
        width: 100%;
    }
}