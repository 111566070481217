$feature-redesign: false;
@import '../common/mixins';

.two-grid-container-30-70 {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 3fr) minmax(0, 7fr);
    gap: 20px;
    grid-gap: 20px;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &.small-card-right {
        grid-template-columns: minmax(0, 7fr) minmax(0, 3fr) !important;
    }

    .left-container,
    .right-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .text-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
    .card-container {
        width: 100%;
        height: 100%;
    }

    .amp-dc-card {
        height: 100%;
    }

    .amp-dc-image-picture-wrap,
    .amp-dc-image-picture-wrap img {
        height: 100%;
    }

    .amp-dc-video,
    .amp-dc-image,
    .amp-dc-card-wrap,
    .amp-dc-card-img-wrap,
    .amp-dc-video-wrap {
        height: 100%;
        width: 100%;
    }

    .content-wrapper {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 768px) {
        .grid-tile:not(.grid-tile__text) {
            .grid-tile__wrapper {
                height: auto;
            }
        }
    }

    @include text-ratio(1, 1, "desktop");
    @include text-ratio(4, 3, "desktop");
    @include text-ratio(9, 16, "desktop");
    @include text-ratio(16, 4, "desktop");
    @include text-ratio(16, 9, "desktop");

    @include text-ratio(1, 1, "tablet");
    @include text-ratio(4, 3, "tablet");
    @include text-ratio(9, 16, "tablet");

    @include text-ratio(3, 4, "mobile");
    @include text-ratio(1, 1, "mobile");

    .grid-tile__text {
        overflow: hidden;
    }
}

.two-grid-container-70-30 {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 7fr) minmax(0, 3fr);
    gap: 20px;
    grid-gap: 20px;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .left-container,
    .right-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .text-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
    .card-container {
        width: 100%;
        height: 100%;
    }

    .amp-dc-card {
        height: 100%;
    }

    .amp-dc-image-picture-wrap,
    .amp-dc-image-picture-wrap img {
        height: 100%;
    }

    .amp-dc-video,
    .amp-dc-image,
    .amp-dc-card-wrap,
    .amp-dc-card-img-wrap,
    .amp-dc-video-wrap {
        height: 100%;
        width: 100%;
    }

    .content-wrapper {
        width: 100%;
        height: 100%;
    }
    
    @media screen and (max-width: 768px) {
            .grid-tile:not(.grid-tile__text) {
                .grid-tile__wrapper {
                    height: auto;
                }
            }
        }

    @include text-ratio(1, 1, "desktop");
    @include text-ratio(4, 3, "desktop");
    @include text-ratio(9, 16, "desktop");
    @include text-ratio(16, 4, "desktop");
    @include text-ratio(16, 9, "desktop");

    @include text-ratio(1, 1, "tablet");
    @include text-ratio(4, 3, "tablet");
    @include text-ratio(9, 16, "tablet");

    @include text-ratio(3, 4, "mobile");
    @include text-ratio(1, 1, "mobile");

    .grid-tile__text {
        overflow: hidden;
    }
}

@media screen and (max-width: 768px) {
    .two-grid-container-30-70 {
        grid-template-columns: 100%;
        gap: 15px;
        grid-gap: 15px;
    }

    .two-grid-container-70-30 {
        grid-template-columns: 100%;
        gap: 15px;
        grid-gap: 15px;
    }
}