$feature-redesign: false;
@import '../common/mixins';

.three-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    grid-gap: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &[data-ratio="1:1"] {
        @include aspect-ratio-styles(1, 1, ".main-card .amp-dc-image");
        @include aspect-ratio-styles(2, 1, ".card-container:not(.wide-card__text)");
    }

    &[data-ratio="3:4"] {
        @include aspect-ratio-styles(3, 4, ".main-card .amp-dc-image");
        @include aspect-ratio-styles(3, 2, ".card-container:not(.wide-card__text)");
    }

    &[data-ratio="4:3"] {
        @include aspect-ratio-styles(4, 3, ".main-card .amp-dc-image");
        @include aspect-ratio-styles(8, 3, ".card-container:not(.wide-card__text)");
    }

    @media screen and (min-width: 768px) {
        &[data-ratio="1:1"] {
            @include aspect-ratio-styles(2, 1, ".wide-card__text");
        }

        &[data-ratio="3:4"] {
            @include aspect-ratio-styles(3, 2, ".wide-card__text");
        }

        &[data-ratio="4:3"] {
            @include aspect-ratio-styles(8, 3, ".wide-card__text");
        }
    }

    .right-container {
        display: grid;
        grid-template-rows: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }

    .amp-dc-component_text {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .right-container a {
        height: 100%;
        text-decoration: none;
    }

    .amp-dc-card-list__area,
    .amp-dc-card,
    .amp-dc-image,
    .amp-dc-image-picture-wrap,
    .right-container,
    .card-container {
        height: 100%;
    }

    .main-card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        &__wrapper {
            display: flex;
            width: 100%;
            height: 100%;

            .amp-dc-hero-banner {
                width: 100%;
            }
        }
    }

    .left-container,
    .right-container {
        width: 100%;
        height: 100%;

        picture {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: baseline;

            img {
                display: inline-block;
                object-fit: cover;
                object-position: 50% top;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .three-grid-container {
        grid-template-columns: 100%;
        grid-template-rows: auto;
        gap: 15px;
        grid-gap: 15px;

        .right-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }
}