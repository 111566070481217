@use "sass:math";

@mixin aspect-ratio-breakpoint($width, $height, $device, $container) {
    @if $device == "desktop" {
        @media screen and (min-width: 1024px) {
            &[data-ratio-desktop="#{$width}:#{$height}"] {
                @include aspect-ratio-styles($width, $height, $container);
            }
        }
    } @else if $device == "tablet" {
        @media screen and (max-width: 1024px) and (min-width: 768px) {
            &[data-ratio-tablet="#{$width}:#{$height}"] {
                @include aspect-ratio-styles($width, $height, $container);
            }
        }
    } @else {
        @media screen and (max-width: 768px) {
            &[data-ratio-mobile="#{$width}:#{$height}"] {
                @include aspect-ratio-styles($width, $height, $container);
            }
        }
    }
}

@mixin aspect-ratio-styles ($width, $height, $container) {
    #{$container} {
        @if $container == ".amp-dc-video-container" {
            & > a {
                width: 100%;
            }
        }

        display: inline-block;
        font-size: 0;
        overflow: hidden;
        position: relative;
        vertical-align: top;
        width: 100%;

        &::before {
            content: "";
            float: left;
            padding-bottom: percentage(math.div($height, $width));
        }

        & > * {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

@mixin text-ratio($width, $height, $device) {
    @if $device == "desktop" {
        @media screen and (min-width: 1024px) {
            &[data-ratio-desktop="#{$width}:#{$height}"] {
                .grid-tile:not(.grid-tile__text) {
                    .grid-tile__wrapper {
                        height: auto;
                    }
                }

                &[data-only-text="false"] {
                    .grid-tile__text {
                        position: relative;

                        .grid-tile__wrapper {
                            position: absolute;
                        }
                    }
                }
            }
        }
    } @else if $device == "tablet" {
        @media screen and (max-width: 1024px) and (min-width: 768px) {
            &[data-ratio-tablet="#{$width}:#{$height}"] {
                .grid-tile:not(.grid-tile__text) {
                    .grid-tile__wrapper {
                        height: auto;
                    }
                }

                &[data-only-text="false"] {
                    .grid-tile__text {
                        position: relative;

                        .grid-tile__wrapper {
                            position: absolute;
                        }
                    }
                }
            }
        }
    } @else {
        @media screen and (max-width: 768px) {
            &[data-ratio-mobile="#{$width}:#{$height}"] {
                .grid-tile:not(.grid-tile__text) {
                    [data-cta-bottom="true"] &, [data-mobile-layout="true"] & {
                        .grid-tile__wrapper {
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}