$feature-redesign: false;
.amp-dc-slider {
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    * {
        margin: 0;
        padding: 0;
    }

    .amp-dc-slider-frame {
        padding-bottom: 50px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }

    .amp-dc-slider-slides {
        font-size: 0;

        &>li {
            display: inline-block;
            overflow: hidden;
            vertical-align: middle;
            width: 100%;
            text-align: center;
            font-size: 22px;
            position: relative;
            line-height: 1;
        }
    }

    .amp-dc-slider-next,
    .amp-dc-slider-prev {
        position: absolute;
        top: 50%;
        margin-top: -25px;
        display: block;
        cursor: pointer;
        user-select: none;

        svg {
            cursor: pointer;
            width: 40px;
            height: 40px;
            fill: #fff;
            background: rgba(0, 0, 0, 0.5);
        }

        &.ctrl-disabled {
            opacity: 0.3;
            -webkit-tap-highlight-color: transparent;
            cursor: default;

            svg {
                cursor: default;
            }
        }
    }

    .amp-dc-slider-next {
        right: 0;
    }

    .amp-dc-slider-prev {
        left: 0;
    }

    .amp-dc-dots {
        user-select: none;
        margin: 10px 0 0 0;
        padding: 0;
        text-align: center;
        position: absolute;
        width: 100%;
        -webkit-tap-highlight-color: transparent;
    }

    .amp-dc-dots>li {
        border: 1px solid #666;
        border-radius: 50%;
        box-shadow: inset 1px 1px 1px #888;
        display: inline-block;
        height: 25px;
        width: 25px;
        margin: 0 5px;
        cursor: pointer;

        &.active {
            background-color: #41ABE5;
        }
    }
}

.lory-slider {
    @media screen and (max-width: 768px) {
        //Put mobile styles here
    }
}