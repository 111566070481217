$feature-redesign: false;
@import "../next-variables.scss";
@import '../next-typography.scss';

.next-cta {
    margin: 10px;
    text-decoration: none;
    color: $textBlack;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $textBlackBg;
    height: var(--cta-height);
    width: var(--cta-width);
    pointer-events: all;
    @include n8;

    @media screen and (min-width: $lg) {
        margin: 20px;
    }

    &:hover {
        color: $earthenBrown;
        text-decoration: none;
        background: white;
    }

    &__float {
        position: sticky;
        top: calc(100% - var(--cta-vertical-margin) - var(--cta-height));
        bottom: initial;
        margin-bottom: 0;
    }

    &.journal {
        border: 1px solid $textBlack;

        .non-touch &:not(:disabled):not(.m-disabled):hover {
            border-color: $earthenBrown;
        }
    }

    &.inverse {
        background-color: $black;
        color: $white;
        border: 1px solid $textBlack;

        .non-touch &:not(:disabled):not(.m-disabled):hover {
            background-color: $white;
            color: $black;
        }
    }

    &.tertiary {
        border: 1px solid $textBlack;

        .non-touch &:not(:disabled):not(.m-disabled):hover {
            background-color: $black;
            color: $white;
        }
    }

    &.sale {
        background: $wattle-red;
        color: $hush-white;

        .non-touch &:not(:disabled):not(.m-disabled):hover {
            background: $gazpacho;
        }
    }
}
