$feature-redesign: false;
.amp-dc-text {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    color: #565656;

    li {
        list-style-position: outside;
    }
}