$feature-redesign: false;
.video-poster {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    
    @media (min-width: 768px) {
        &.mobile {
            background: transparent !important;
        }
    }
}

.next-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}