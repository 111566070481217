$feature-redesign: false;
.amp-dc-card {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    * {
        margin: 0;
        padding: 0;
    }

    .amp-dc-hide {
        display: none;
    }

    .amp-dc-card-text-wrap {
        padding: 10px 0;
        text-align: center;
        background-color: #fff;
    }

    a.amp-dc-card-wrap {
        display: block;
        text-decoration: none;

        &:hover {
            .amp-dc-card-link {
                text-decoration: underline;
            }
        }
    }

    .amp-dc-card-name {
        font-size: 21px;
        color: #000;
        margin-bottom: 5px;
    }

    .amp-dc-card-description {
        font-size: 14px;
        color: #8F8F8F;
        display: inline-block;
    }

    .amp-dc-card-link {
        margin-top: 7px;
        color: #8F8F8F;
        font-size: 16px;
        font-weight: bold;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        white-space: nowrap;
    }
}