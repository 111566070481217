$feature-redesign: false;
@use "sass:list";

$grid_gap: 20px;

@mixin text-ratio($width, $height, $device) {
    @if $device == "desktop" {
        @media screen and (min-width: 1024px) {
            &[data-ratio-desktop="#{$width}:#{$height}"] .staggered_component_text {
                aspect-ratio: list.slash($width, $height * 0.5);
            }
        }
    } @else if $device == "tablet" {
        @media screen and (max-width: 1024px) and (min-width: 768px) {
            &[data-ratio-tablet="#{$width}:#{$height}"] .staggered_component_text {
                aspect-ratio: list.slash($width, $height * 0.5);
            }
        }
    } @else {
        @media screen and (max-width: 768px) {
            &[data-ratio-mobile="#{$width}:#{$height}"] .staggered_component_text {
                aspect-ratio: list.slash($width, $height * 0.5);
            }
        }
    }
}

.staggered-images-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 100%;
    gap: $grid_gap;
    grid-gap: $grid_gap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    @include text-ratio(1, 1, "desktop");
    @include text-ratio(4, 3, "desktop");
    @include text-ratio(9, 16, "desktop");
    @include text-ratio(16, 4, "desktop");
    @include text-ratio(16, 9, "desktop");

    @include text-ratio(1, 1, "tablet");
    @include text-ratio(4, 3, "tablet");
    @include text-ratio(9, 16, "tablet");

    @include text-ratio(3, 4, "mobile");
    @include text-ratio(1, 1, "mobile");

    .left-container,
    .right-container {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: repeat(3, minmax(0, 1fr));
        gap: $grid_gap;
        grid-gap: $grid_gap;
        width: 100%;
        height: 100%;

        .image_container--wrapper {
            display: flex;
            width: 100%;
            height: 100%;
        }

        .staggered_component_image {
            display: flex;
            justify-content: center;
            height: 100%;

            .amp-dc-hero-banner {
                width: 100%;
            }

            picture {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: baseline;

                img {
                    display: inline-block;
                    object-fit: cover;
                    object-position: 50% top;
                    height: 100%;
                }
            }
        }
    }

    .left-container {
        .staggered_component_image {
            grid-row: 1 / span 2;
        }

        .staggered_component_text {
            grid-row: 3 / span 1;
        }
    }

    .right-container {
        .staggered_component_text {
            grid-row: 1 / span 1;
        }

        .staggered_component_image {
            grid-row: 2 / span 2;
        }
    }

    .amp-dc-component_text {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .amp-dc-card-list__area,
    .amp-dc-card,
    .amp-dc-image,
    .amp-dc-image-picture-wrap,
    .right-container {
        height: 100%;
    }

    .amp-dc-video,
    .amp-dc-card-wrap,
    .amp-dc-card-img-wrap,
    .amp-dc-video-wrap {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .staggered-images-container {
        gap: #{$grid_gap - 5px};
        grid-gap: #{$grid_gap - 5px};
        grid-template-columns: 100%;
        grid-template-rows: repeat(2, minmax(0, 1fr));

        .staggered_component_text {
            display: none;
        }

        .left-container,
        .right-container {
            grid-template-columns: 100%;
            grid-template-rows: 100%;
            gap: #{$grid_gap - 5px};
            grid-gap: #{$grid_gap - 5px};

            .staggered_component_image {
                grid-row: 1 / span 1;
            }
        }
    }
}
