$feature-redesign: false;
@import "../next-variables.scss";

.next-content-blocks {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;

    .next-block {
        .next-image {
            width: 100%;
        }
    }

    @media screen and (min-width: $sm) {
        flex-direction: row;

        .next-block {
            flex-basis: 50%;
            height: var(--homepage-item-height);
        }
    }

    .content-blocks-absolute-wrap {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        padding: 24px;
        pointer-events: none;

        @media screen and (min-width: $lg) {
            padding: 32px;
        }

        p {
            margin: 0;
        }

        .next-text {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 24px;
            }

            @media screen and (min-width: $lg) {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 32px;
                }
            }
        }
    }

    .align-to-bottom .content-text-blocks {
        position: absolute;
        bottom: 61px;

        @media screen and (min-width: $xs) {
            bottom: 64px;
        }

        @media screen and (min-width: $sm) {
            bottom: 61px;
        }

        @media screen and (min-width: 992px) {
            bottom: 62px;
        }

        @media screen and (min-width: $lg) {
            bottom: 72px;
        }

        @media screen and (min-width: $xxl) {
            bottom: 80px;
        }
    }

    .content-cta-wrap {
        position: absolute;
        bottom: 24px;

        @media screen and (min-width: $lg) {
            bottom: 32px;
        }

        .next-cta {
            margin: 0;
        }
    }
}
