$feature-redesign: false;
@import "../next-variables.scss";
@import '../next-typography.scss';

.next-block {
    --cta-height: 3rem;
    --cta-width: calc(var(--cta-height) * 7);

    --cta-vertical-margin: 25px;
    --cta-horizontal-margin: 25px;

    @media screen and (min-width: $sm) {    
        --cta-vertical-margin: 20px;
        --cta-horizontal-margin: 20px;
    }

    @media screen and (min-width: $lg) {    
        --cta-vertical-margin: 40px;
        --cta-horizontal-margin: 40px;
    }

    flex: 1;
    position: relative;
    height: 100%;
    max-height: 100%;

    &__background {
        width: 100%;
    }

    &__wrapper-link {
        display: flex;
        width: 100%;
        height: var(--homepage-item-height);
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &.story {
            height: fit-content;
            @media screen and (min-width: $sm) {
                height: var(--homepage-item-height);
            }            
        }        
    }

    &__cta-wrapper {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        padding-bottom: var(--cta-vertical-margin);
    }

    &__cta {
        position: absolute;
        bottom: 0;
        margin: var(--cta-vertical-margin) var(--cta-horizontal-margin);
        text-decoration: none;
        color: $textBlack;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $textBlackBg;
        height: var(--cta-height);
        width: var(--cta-width);
        pointer-events: all;
        @include n8;

        &:hover {
            color: $earthenBrown;
            text-decoration: none;
            background: white;
        }
        
        &__float {
            position: sticky;
            top: calc(100% - var(--cta-vertical-margin) - var(--cta-height));
            bottom: initial;
            margin-bottom: 0;
        }

        &__keyline {
            border: 1px solid $textBlack;

            &:hover {
                border-color: $earthenBrown;
            }
        }
    }
}
