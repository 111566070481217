$feature-redesign: false;
.amp-dc-homepage {
    display: grid;
    gap: 20px;
    grid-gap: 20px;
    margin: 0 auto;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    grid-template-rows: auto;
    grid-template-columns: 100%;

    .amp-dc-block-wrap {
        width: 100%;
    }
}