$feature-redesign: false;
@import '../common/mixins';

.two-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    grid-gap: 20px;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .left-container,
    .right-container {
        width: 100%;
        height: 100%;
    }
    
    .card-container {
        width: 100%;
        height: 100%;
    }

    .amp-dc-card {
        height: 100%;
    }

    .amp-dc-hero-banner {
        width: 100%;
        height: 100%;
    }

    .amp-dc-video,
    .amp-dc-image,
    .amp-dc-card-wrap,
    .amp-dc-card-img-wrap,
    .amp-dc-video-wrap {
        height: 100%;
        width: 100%;
    }

    .grid-tile {
        &__wrapper {
            display: flex;
            height: 100%;

            .amp-dc-hero-banner {
                width: 100%;
            }
        }
    }

    &[data-mobile-layout="true"],
    &[data-cta-bottom="true"] {
        @media screen and (max-width: 768px) {
            .grid-tile:not(.grid-tile__text) {
                .grid-tile__wrapper {
                    height: auto;
                }
            }
        }
    }

    @include text-ratio(1, 1, "desktop");
    @include text-ratio(4, 3, "desktop");
    @include text-ratio(9, 16, "desktop");
    @include text-ratio(16, 4, "desktop");
    @include text-ratio(16, 9, "desktop");

    @include text-ratio(1, 1, "tablet");
    @include text-ratio(4, 3, "tablet");
    @include text-ratio(9, 16, "tablet");

    @include text-ratio(3, 4, "mobile");
    @include text-ratio(1, 1, "mobile");

    .amp-dc-component_text {
        overflow: hidden;
    }
}

@media screen and (max-width: 1024px) {
    .two-grid-container {
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .two-grid-container {
        grid-template-columns: 100%;
        gap: 15px;
        grid-gap: 15px;
    }
}