$white: #fff !default;
$black: #000 !default;
$font-family-theinhardt: "Theinhardt", Arial, sans-serif;
$font-family-sans: "ABCROM Book" Arial, sans-serif;
$font-family-serif: "PortraitText", "Times New Roman", serif;
$font-size-primary:   13px;
$font-size-secondary: 12px;
$transition-function     : ease-out;
$transition-timing-short : 0.2s;
$transition-timing       : 0.4s;
$transition-timing-long  : 1.2s;
$transition-ease-short   : $transition-function $transition-timing-short;
$transition-ease         : $transition-function $transition-timing;
$transition-ease-long    : $transition-function $transition-timing-long;
$gray-100: #ececec;
$gray-200: #ccc;
$gray-300: #989898;