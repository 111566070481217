$feature-redesign: false;
@import '../next-variables.scss';

.next-twin {
    display: flex;
    width: 100%;
    flex-direction: column;
        
    @media screen and (min-width: $sm) {
        height: var(--homepage-item-height);
        flex-direction: row;
        > * {
            width: 50%;
        }
    }
}