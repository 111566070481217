$feature-redesign: false;
.amp-dc-image {
    min-width: 0;
}

.amp-dc-card-title {
    font-size: 2em;
    text-align: center;
}

.amp-dc-card-list {
    .amp-dc-card-list-wrap {
        font-size: 0;
        margin-top: 20px;
        margin-left: -50px;
    }

    .amp-dc-card {
        display: inline-block;
        vertical-align: top;
    }

    .amp-dc-card-wrap {
        margin: 0 0 50px 51px;

        .amp-dc-card-img {
            max-height: 250px;
        }
    }

    &.amp-dc-cards-hero {

        .amp-dc-card-list-wrap {
            margin-left: 0;
            overflow: hidden;


            >div:nth-last-child(3):first-child {
                float: left;
                width: 70%;

                .amp-dc-card-wrap {
                    margin-right: 20px;
                }
            }
        }

        .amp-dc-card-wrap {
            margin: 0 0 50px 0;
        }

        .amp-dc-card {
            float: right;
            width: 30%;

            &.amp-dc-card-1 {
                float: left;
                width: 70%;

                .amp-dc-card-wrap {
                    margin-right: 20px;
                }
            }
        }
    }
}

.amp-dc-prod-5-rows {
    .amp-dc-card {
        width: 20%;
    }
}

.amp-dc-prod-4-rows {
    .amp-dc-card {
        width: 25%;
    }
}

.amp-dc-prod-3-rows {
    .amp-dc-card {
        width: 33.33%;
    }
}

.amp-dc-prod-2-rows {
    .amp-dc-card {
        width: 50%;
    }
}

.amp-dc-prod-1-rows {
    .amp-dc-card {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .amp-dc-prod-5-rows {
        .amp-dc-card {
            width: 50%;
        }
    }

    .amp-dc-prod-4-rows {
        .amp-dc-card {
            width: 50%;
        }
    }

    .amp-dc-prod-3-rows {
        .amp-dc-card {
            width: 50%;
        }

        &.amp-dc-cards-hero {
            .amp-dc-card-list-wrap {
                >div:nth-last-child(3):first-child {
                    width: 100%;

                    .amp-dc-card-wrap {
                        margin-right: 0;
                    }
                }

                .amp-dc-card {
                    width: 100%;

                    &.amp-dc-card-1 {
                        width: 100%;

                        .amp-dc-card-wrap {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .amp-dc-prod-2-rows {
        .amp-dc-card {
            width: 50%;
        }
    }

    .amp-dc-prod-1-rows {
        .amp-dc-card {
            width: 100%;
        }
    }
}

@media screen and (max-width: 480px) {
    .amp-dc-prod-5-rows {
        .amp-dc-card {
            width: 100%;
        }
    }

    .amp-dc-prod-4-rows {
        .amp-dc-card {
            width: 100%;
        }
    }

    .amp-dc-prod-3-rows {
        .amp-dc-card {
            width: 100%;
        }
    }

    .amp-dc-prod-2-rows {
        .amp-dc-card {
            width: 100%;
        }
    }

    .amp-dc-prod-1-rows {
        .amp-dc-card {
            width: 100%;
        }
    }
}