$feature-redesign: false;
@import '../common/mixins';

.three-grid-container-25-25-50 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 20px;
    grid-gap: 20px;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .amp-dc-image-picture-wrap,
    .amp-dc-image-picture-wrap img {
        height: 100%;
    }

    .amp-dc-video,
    .amp-dc-image,
    .amp-dc-card-wrap,
    .amp-dc-card-img-wrap,
    .amp-dc-video-wrap {
        height: 100%;
        width: 100%;
    }

    &[data-mobile-layout="true"],
    &[data-cta-bottom="true"] {
        @media screen and (max-width: 768px) {
            .grid-tile:not(.grid-tile__text) {
                .grid-tile__wrapper {
                    height: auto;
                }
            }
        }
    }

    @include text-ratio(1, 1, "desktop");
    @include text-ratio(4, 3, "desktop");
    @include text-ratio(9, 16, "desktop");
    @include text-ratio(16, 4, "desktop");
    @include text-ratio(16, 9, "desktop");

    @include text-ratio(1, 1, "tablet");
    @include text-ratio(4, 3, "tablet");
    @include text-ratio(9, 16, "tablet");

    @include text-ratio(3, 4, "mobile");
    @include text-ratio(1, 1, "mobile");

    .grid-tile__text {
        overflow: hidden;
    }
}

.three-grid-container-50-25-25 {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 20px;
    grid-gap: 20px;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .amp-dc-image-picture-wrap,
    .amp-dc-image-picture-wrap img {
        height: 100%;
    }

    .amp-dc-video,
    .amp-dc-image,
    .amp-dc-card-wrap,
    .amp-dc-card-img-wrap,
    .amp-dc-video-wrap {
        height: 100%;
        width: 100%;
    }

    &[data-mobile-layout="true"],
    &[data-cta-bottom="true"] {
        @media screen and (max-width: 768px) {
            .grid-tile:not(.grid-tile__text) {
                .grid-tile__wrapper {
                    height: auto;
                }
            }
        }
    }

    @include text-ratio(1, 1, "desktop");
    @include text-ratio(4, 3, "desktop");
    @include text-ratio(9, 16, "desktop");
    @include text-ratio(16, 4, "desktop");
    @include text-ratio(16, 9, "desktop");

    @include text-ratio(1, 1, "tablet");
    @include text-ratio(4, 3, "tablet");
    @include text-ratio(9, 16, "tablet");

    @include text-ratio(3, 4, "mobile");
    @include text-ratio(1, 1, "mobile");

    .grid-tile__text {
        overflow: hidden;
    }
}

@media screen and (max-width: 768px) {
    .three-grid-container-25-25-50 {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        grid-gap: 15px;

        .right-side-content {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    .three-grid-container-50-25-25 {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        grid-gap: 15px;

        .left-side-content {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;
        }
    }
}