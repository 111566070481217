$feature-redesign: false;
.amp-dc-component_text {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    white-space: normal;
    font-weight: 400;

    @if ($feature-redesign) {
        font-weight: normal;
    }

    li {
        list-style-position: outside;
    }

    &--paragraph {
        padding: 0;
        margin: 0;

        &__pull {
            display: inline-block;
            font-size: 19px;
        }
    }
}
