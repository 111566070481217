$feature-redesign: false;
.amp-dc-hero-banner {
    display: flex;

    .banner-image-desktop,
    .banner-image-mobile,
    .banner-video-mobile,
    .banner-video-desktop,
    .banner-video-desktop-and-mobile,
    .banner-video-mobile-and-desktop {
        user-select: none;
        margin: 0 auto;
        display: block;
        width: 100%;
        height: 100%;
    }

    .banner-image-mobile,
    .banner-video-mobile {
        display: none;
    }

    .banner-image-desktop-and-mobile,
    .banner-image-mobile-and-desktop,
    .amp-dc-video-wrap, 
    .amp-dc-video {
        width: 100%;
        height: 100%;
    }

    .amp-dc-hero-banner-pic-wrap,
    .banner-image-desktop {
        height: 100%;
    }

    @media screen and (max-width: 1024px) {
        .amp-dc-hero-banner,
        .banner-image-desktop-and-mobile,
        .amp-dc-image,
        .amp-dc-image img {
            height: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .banner-image-desktop,
        .banner-video-desktop {
            display: none;
        }
        .banner-image-mobile,
        .banner-video-mobile {
            display: block;
        }
    }
}

.margin-sm-10 {
    margin: 10px;
}

.margin-sm-20 {
    margin: 20px;
}

.margin-sm-50 {
    margin: 50px;
}

.margin-sm-100 {
    margin: 100px;
}

.margin-sm-200 {
    margin: 200px;
}

@media screen and (min-width: 768px) {
    .margin-md-10 {
        margin: 10px;
    }

    .margin-md-20 {
        margin: 20px;
    }

    .margin-md-50 {
        margin: 50px;
    }

    .margin-md-100 {
        margin: 100px;
    }

    .margin-md-200 {
        margin: 200px;
    }
}

@media screen and (min-width: 1024px) {
    .margin-10 {
        margin: 10px;
    }

    .margin-20 {
        margin: 20px;
    }

    .margin-50 {
        margin: 50px;
    }

    .margin-100 {
        margin: 100px;
    }

    .margin-200 {
        margin: 200px;
    }
}
