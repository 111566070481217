$feature-redesign: false;
.amp-dc-carousel {
    position: relative;
    white-space: nowrap;
    margin-left: 50px;
    margin-right: 50px;

    .amp-dc-slide {
        padding: 0 10px;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .amp-dc-carousel-frame {
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }

    .amp-dc-carousel-slides > li {
        display: inline-block;
        overflow: hidden;
        text-align: center;
        line-height: 1;
    }

    .amp-dc-carousel-next,
    .amp-dc-carousel-prev {
        top: 50%;
        position: absolute;
        transform: translate(0, -50%);
        display: block;
        cursor: pointer;
        user-select: none;

        svg {
            cursor: pointer;
            width: 30px;
            height: 30px;
            fill: #000;
        }

        &.ctrl-disabled {
            -webkit-tap-highlight-color: transparent;
            cursor: default;

            svg {
                cursor: default;
                fill: rgb(204, 204, 204);
            }
        }
    }

    .amp-dc-carousel-next {
        right: -32px;
    }

    .amp-dc-carousel-prev {
        left: -32px;
    }

    @media screen and (max-width: 768px) {
        &[data-mobile="true"] {
            &[data-mobile-perview="1"] {
                .amp-dc-carousel-slide {
                    width: 100% !important;
                }
            }
            &[data-mobile-perview="2"] {
                .amp-dc-carousel-slide {
                    width: 50% !important;
                }
            }
            &[data-mobile-perview="3"] {
                .amp-dc-carousel-slide {
                    width: 33.3% !important;
                }
            }
            &[data-mobile-perview="4"] {
                .amp-dc-carousel-slide {
                    width: 25% !important;
                }
            }
        }

        .amp-dc-slide {
            padding: 0 7px;
        }

        .amp-dc-carousel-next {
            right: -30px;
        }
    
        .amp-dc-carousel-prev {
            left: -30px;
        }
    }
}