$feature-redesign: false;
@import '../next-typography.scss';

$font-family-sans: 'ABCROM Book', arial, sans-serif;
$font-family-serif: 'PortraitText', 'Times New Roman', serif;
$black: #000;
$hush-white: #fcf9f6;
$earthen-brown: #57321d;
$ocean-blue: #b2d6e4;
$wattle-red: #d23c2c;

$grid-breakpoints: (
    xs: 0,
    sm: 320px,
    md: 768px,
    lg: 1200px,
    xl: 1440px,
);

.next-journal-content {
    border-left: 1px solid $black;
    border-right: 1px solid $black;
    margin: 20px 12px 0;
    padding: 0 13px 100px;
    --td-gutter: 69px;

    @media (width > map-get($grid-breakpoints, 'md')) {
        max-width: 1415px;
        padding: 0 0 200px;
        margin: 40px 10px 0;
    }

    @media (width > map-get($grid-breakpoints, 'lg')) {
        --td-gutter: 126px;
    }

    @media (width > map-get($grid-breakpoints, 'xl')) {
        margin: 64px auto 0;
    }    

    .table {
        width: auto;
        margin-bottom: 0;

        td {
            border: none;
            vertical-align: middle;
        }
    }

    .btn-primary {
        background-color: transparent !important;
        border: none;
        display: block;
        text-transform: none;

        a {
            text-decoration: none;
        }
    }

    @media screen and (max-width: 768px) {
        >figure:first-of-type[style] {
            width: 224px !important;
        }

        .image-caption {
            margin-bottom: 57px !important;
        }
    }

    a.link-icon::after {
        font-family: $font-family-sans;
    }

    p>img[src*="Pause"],
    p>img[src*="pause"] {
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: -0.02em
    }

    h1 {
        @include n2;

        margin: 10px 0 40px;

        @media (width >=map-get($grid-breakpoints, 'md')) {
            margin-bottom: 80px;
        }

        @media (width >=map-get($grid-breakpoints, 'lg')) {
            margin: 64px 0 120px;
        }
    }

    p {
        @include n4;

        margin: 0 0 34px;

        @media (width >=map-get($grid-breakpoints, 'sm')) {
            margin: 0 0 40px;
        }

        @media (width > map-get($grid-breakpoints, 'md')) {
            margin: 0 0 52px;
        }
    }

    a {
        font-family: $font-family-serif;
        color: black;
        text-decoration: none;

        &:hover,
        &:active {
            color: $earthen-brown;
        }

        &.link-icon {
            &::after {
                content: '\00a0 \2192';
                display: inline-block;
            }
        }
    }

    .image-caption {
        @include n6;

        margin: 0 0 100px;

        @media (width >=map-get($grid-breakpoints, 'md')) {
            margin: 0 0 120px;
        }
    }

    .image-centered {
        margin-left: auto;
        margin-right: auto;
    }

    .pullout-quote {
        @include n2;

        margin: 0 0 100px;

        @media (width >=map-get($grid-breakpoints, 'md')) {
            margin: 0 0 120px;
        }
    }

    .tombstone {
        &::after {
            content: ' ';
            height: 16px;
            border-style: double;
            border-width: 0 0 0 14px;
            border-color: $wattle-red;
            display: inline-block;
            margin-left: 10px;
            line-height: 2.2rem;
            vertical-align: middle;
        }
    }

    .para-title-indent {
        padding-left: 58px;

        @media (width >=map-get($grid-breakpoints, 'md')) {
            padding-left: 118px;
        }
    }

    figure {
        &.image {
            margin-top: 40px;
            display: inline-flex;

            >:first-child {
                min-width: 50%;
                margin: auto;
            }

            @media (width >=map-get($grid-breakpoints, 'md')) {
                margin-top: 80px;
            }

            @media (width >=map-get($grid-breakpoints, 'lg')) {
                margin-top: 120px;
            }

            &-1 {
                @media (width > map-get($grid-breakpoints, 'md')) {
                    width: 80% !important;
                }
            }

            &-2 {
                @media (width > map-get($grid-breakpoints, 'md')) {
                    width: 64% !important;
                }
            }

            &-3 {
                @media (width > map-get($grid-breakpoints, 'md')) {
                    width: 48% !important;
                }
            }
        }
    }

    .media {
        iframe {
            top: unset !important;
        }
    }

    .indent {
        &-1 {
            @media (width > map-get($grid-breakpoints, 'md')) {
                margin-right: 10%;
                margin-left: 10%;
            }
        }

        &-2 {
            @media (width > map-get($grid-breakpoints, 'md')) {
                margin-right: 18%;
                margin-left: 18%;
            }
        }

        &-3 {
            @media (width > map-get($grid-breakpoints, 'md')) {
                margin-right: 26%;
                margin-left: 26%;
            }
        }
    }

    .btn {
        margin-top: 10px;
        margin-bottom: 100px;

        @media (width > map-get($grid-breakpoints, 'md')) {
            margin-top: 80px;
        }

        @media (width > map-get($grid-breakpoints, 'lg')) {
            margin-bottom: 200px;
        }

        a {
            @include n8;

            border: 1px solid $black;
            text-align: center;
            cursor: pointer;
            padding: 15px;
            text-decoration: none;
            color: $black;
        }
    }

    .table {
        margin-top: 0;
        margin-bottom: 0;

        @media (width <=map-get($grid-breakpoints, 'md')) {
            margin: 0;
        }
    }

    table,
    tr,
    td {
        width: 100%;
        display: block;
        border: none;

        @media (width >=map-get($grid-breakpoints, 'md')) {
            width: auto;
            display: table;
        }
    }

    table {
        @media (width >=map-get($grid-breakpoints, 'md')) {
            display: table;
        }

        tr {
            @media (width >=map-get($grid-breakpoints, 'md')) {
                display: table-row;
            }
        }

        td {
            @media (width >=map-get($grid-breakpoints, 'md')) {
                display: table-cell;
                width: 50%;
                padding: 0 4.25%;
            }

            .image {
                margin: 0;

                &:has(+ p > a:first-child) {
                    margin: 0 0 15px;                    
                }
            }

            p {
                @include n6;

                margin: 0 0 90px;

                @media (width >=map-get($grid-breakpoints, 'md')) {
                    margin: 0 0 120px;
                }

                >a:first-child {
                    @include n5;

                    margin: 0 0 10px;
                    display: block;

                    @media (width >=map-get($grid-breakpoints, 'md')) {
                        margin: 0 0 15px;
                    }

                    @media (width >=map-get($grid-breakpoints, 'lg')) {
                        margin: 0 0 20px;
                    }
                }
            }
        }

        td:nth-child(odd) {
            @media (width >=map-get($grid-breakpoints, 'md')) {
                padding: 0 var(--td-gutter) 0 0;
            }
        }

        td:nth-child(even) {
            @media (width >=map-get($grid-breakpoints, 'md')) {
                padding: 0 0 0 var(--td-gutter);
            }
        }
    }

    .table {

        td {
            padding-top: 0;
            padding-bottom: 0;
        }

        figure.image+p {

            margin-top: 15px;

            @media (width > map-get($grid-breakpoints, 'md')) {
                margin-top: 20px;
            }

            @media (width > map-get($grid-breakpoints, 'lg')) {
                margin-top: 40px;
            }

        }

        &.indent-1 {
            padding: 0 53px;

            @media (width > map-get($grid-breakpoints, 'md')) {
                padding: 0;
                margin-left: 72px;
                margin-right: 72px;
            }

            @media (width > map-get($grid-breakpoints, 'lg')) {
                padding: 0;
                margin-left: 136px;
                margin-right: 136px;
            }
        }
    }

    h1 {
        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 60px;
    
        @media screen and (min-width: 768px) {
          margin-bottom: 80px;
        }
    
        @media screen and (min-width: 1200px) {
          margin-bottom: 160px;
        }
      }
}