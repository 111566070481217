$feature-redesign: false;
@import "../next-variables.scss";

.bleed-cta-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 300px;

    @media screen and (min-width: $sm) {
        width: 620px;
    }

    @media screen and (min-width: $lg) {
        width: 1130px;
    }

    .hideOnMobile {
        display: none;

        @media screen and (min-width: $lg) {
            display: flex;
        }
    }

    .hideOnDesktop {
        display: flex;

        @media screen and (min-width: $lg) {
            display: none;
        }
    }

    &__float {
        width: 100%;
        justify-content: start;
    }

    &__four-cta {
        @media screen and (min-width: $lg) {
            width: 920px;
        }
    }
}

.bleed-text-wrap,
.content-text-blocks {
    .hideOnMobile {
        display: none;

        @media screen and (min-width: $lg) {
            display: block;
        }
    }

    .hideOnDesktop {
        display: block;

        @media screen and (min-width: $lg) {
            display: none;
        }
    }
}

.bleed-float-wrap {
    position: sticky;
    top: 390px;
    overflow: hidden;
    pointer-events: all;
    transition: top 0.4s ease-out;
    pointer-events: none;
}

.bleed-absolute-wrap {
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    transition: padding 0.4s ease-out;
}

.bleed-text-wrap {
    text-align: center;

    p {
        margin: 0;
    }

    .next-text {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 12px;
        }

        @media screen and (min-width: $lg) {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 20px;
            }
        }
    }
}

.next-hero-bleed {
    display: grid;
}

@media screen and (max-width: $sm) {
    .next-template-d__transparent {
        .next-cta {
            margin: 0 0 20px;
        }

        .bleed-cta__float {
            margin-left: 20px;
        }
    }
}
