$feature-redesign: false;
@import '../next-variables.scss';
@import '../next-typography.scss';

.next-duel {
    &__title {
      margin: 50px 0 8px;
      @include n3;
      text-align: center;

      @media screen and (min-width: $lg) {
        margin-top: 160px;
      }
    }
}
