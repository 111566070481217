$feature-redesign: false;
.amp-dc-banner {
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    * {
        margin: 0;
        padding: 0;
    }

    .amp-dc-hide {
        display: none;
    }

    .amp-dc-banner-info {
        font-size: 22px;
        text-align: center;
        background: #fff;
        color: #000;
        padding: 15px;
        min-width: 200px;
    }

    .amp-dc-banner-pic {
        max-width: 100%;
        display: block;

        img {
            width: 100%;
        }
    }

    .amp-dc-banner-img {
        width: 100%;
        display: block;
    }

    .amp-dc-banner-info-wrap {
        color: #fff;
        width: 33.3%;
        height: 100%;
        position: absolute;
        top: 0;
        white-space: normal;

        &[data-align='left'] {
            left: 65px;
        }

        &[data-align='right'] {
            right: 65px;
        }

        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -1em;
            /* Adjusts for spacing */
        }
    }

    .amp-dc-banner-info {
        display: inline-block;
        vertical-align: middle;
    }

    .amp-dc-banner-header {
        font-size: 32px;
    }

    .amp-dc-banner-description,
    .amp-dc-banner-subheader {
        font-size: 16px;
        margin-top: 15px;
    }

    .amp-dc-banner-button {
        font-size: 20px;
        margin-top: 15px;
        display: inline-block;
        min-width: 40%;
        padding: 17px 15px;
        background: #fff;
        text-decoration: none;
        font-weight: bold;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        box-sizing: border-box;

        &.black {
            background: #000;
            color: #fff;
        }

        &.white {
            background: #fff;
            color: #000;
        }
    }

}


.amp-dc-banner {
    @media screen and (max-width: 768px) {
        .amp-dc-banner-info-wrap {
            width: 40%;

            &[data-align='left'] {
                left: 35px;
            }

            &[data-align='right'] {
                right: 35px;
            }

            &.amp-dc-info-bottom {
                position: relative;
                top: 0 !important;
                left: 0 !important;
                right: auto;
                display: block;
                width: 100%;
                text-align: center;
                padding: 0 15px 10px 15px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                .amp-dc-banner-info {
                    display: block;
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .amp-dc-banner-info-wrap {
            width: 50%;

            &[data-align='left'] {
                left: 20px;
            }

            &[data-align='right'] {
                right: 20px;
            }

            &.amp-dc-info-bottom {
                width: 100%;

                &[data-align='left'] {
                    left: 0;
                }

                &[data-align='right'] {
                    right: 0;
                }
            }
        }
    }

}