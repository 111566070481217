$feature-redesign: false;
.amp-dc-slide {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    user-select: none;

    .amp-dc-slide__top {
        margin-bottom: 20px;
    }

    li {
        list-style-position: outside;
    }

    * {
        margin: 0;
        padding: 0;
    }

    &--media {
        width: 100%;

        .amp-dc-video {
            width: 100%;
        }
    }

    &--link {
        width: fit-content;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: baseline;

        &__hover {
            opacity: 1;
            transition: opacity ease-out 0.4s;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    &__top,
    &__bottom {
        min-width: 0;
        min-height: 0;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        letter-spacing: 0.8px;
        font-weight: 400;

        @if ($feature-redesign) {
            font-weight: normal;
        }

        a.amp-dc-slide--link {
            width: 100%;
        }

        &--title {
            margin-bottom: 10px;
        }

        &--title,
        &--subtitle {
            line-height: 1.11;
            width: 100%;

            .amp-dc-component_text {
                display: inline-block;
                white-space: initial;
                width: 100%;
                text-align: left;
            }
        }
    }

    &__top {
        flex: 1;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
