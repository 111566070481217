$feature-redesign: false;
.four-grid-across-container {
    width: 100%;
    height: auto;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .amp-dc-card-list-wrap {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 20px;
        grid-gap: 20px;
    }

    .card-container {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    @media screen and (max-width: 768px) {
        .amp-dc-card-list-wrap{
            grid-template-columns: 1fr 1fr !important;
            gap: 15px;
            grid-gap: 15px;
        }
    }
}